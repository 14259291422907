import { AxiosError } from 'axios';

export const removeFileExtension = (filename: string) => {
  return filename.substring(0, filename.lastIndexOf('.')) || filename;
};

export const getFileExtension = (filename: string) => {
  return filename.split('.')?.pop()?.toLowerCase() || '';
};

export const getAxiosErrorMessage = (error: AxiosError): string => {
  return error?.response?.data?.message;
};

export const getAxiosStatusCode = (error: AxiosError) => {
  return error?.response?.status;
};
