//import { JwtPayload } from 'jsonwebtoken';
import { IAircraft } from './Aircraft';
import { validateEmail } from '../functions/inputValidation';

interface JwtPayload {
  // eslint-disable-next-line
  [key: string]: any;
  iss?: string | undefined;
  sub?: string | undefined;
  aud?: string | string[] | undefined;
  exp?: number | undefined;
  nbf?: number | undefined;
  iat?: number | undefined;
  jti?: string | undefined;
}

enum EUserRole {
  UNAUTHORIZED = -1,
  VIEWER = 1,
  USER = 10,
  OPS = 100,
  ADMIN = 1000,
}

interface IUserTokenPayload extends JwtPayload {
  dbId: string;
  id: string;
  name: string;
  role: EUserRole;
}

interface IUserPasswordResetToken extends JwtPayload {
  dbId: string;
}

interface IUser {
  _id: string;

  user_id: string;
  email: string;
  first_name: string;
  family_name: string;
  password: string;
  salt: string;
  remarks?: string;
  role: EUserRole;
  phone?: string;
  active: boolean;
  termsAccepted: boolean;
  aircraftFilter?: Array<IAircraft>;
}

// Reduced version of IUser with the data that needs to be
// supplied when a new user is created
// (_id, salt & password are automatically generated on the backend)
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUserCreate extends Omit<IUser, '_id' | 'salt' | 'password'> {}

// Returns an array contains the properties (as a string) of an interface
type ExtractStringPropertyNames<T> = {
  [K in keyof T]: T[K] extends string | undefined ? K : never;
}[keyof T];

// Array containing the properties (as a string) of IUserCreate
type IUserStringProperties = NonNullable<
  ExtractStringPropertyNames<IUserCreate>
>;

// A key of IUser
type IUserKeys = keyof IUser;

// The types contained in IUser
type IUserTypes = IUser[IUserKeys];

const IUserKeysMap: Partial<
  Record<
    keyof IUser,
    {
      label: string;
      required: boolean;
      validationFunction: (value: string) => boolean;
      invalidInputText?: string;
    }
  >
> = {
  first_name: {
    label: 'First Name',
    required: true,
    validationFunction: (value) => value.length > 0,
    invalidInputText: "Please enter the user's first name",
  },
  family_name: {
    label: 'Last Name',
    required: true,
    validationFunction: (value) => value.length > 0,
    invalidInputText: "Please enter the user's last name",
  },
  user_id: {
    label: 'User ID',
    required: true,
    validationFunction: (value) => value.length > 2,
    invalidInputText: 'User ID must be at least 3 characters',
  },
  phone: {
    label: 'Phone',
    required: false,
    validationFunction: (_value) => true,
  },
  email: {
    label: 'Email',
    required: true,
    validationFunction: (value) => validateEmail(value),
    invalidInputText: 'Enter a valid e-mail address',
  },
  active: {
    label: 'Active',
    required: true,
    validationFunction: (_value) => true,
  },
  aircraftFilter: {
    label: 'Aircraft filter',
    required: false,
    validationFunction: (_value) => true,
  },
  role: { label: 'Role', required: true, validationFunction: (_value) => true },
};

export {
  IUser,
  EUserRole,
  IUserTokenPayload,
  IUserPasswordResetToken,
  IUserKeysMap,
  IUserKeys,
  IUserTypes,
  IUserStringProperties,
  IUserCreate,
};
