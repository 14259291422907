import axios from 'axios';
import { getAxiosStatusCode, getAxiosErrorMessage } from '@/aux/aux_functions';
import debugLog from '@/aux/debugLog';

export default (error: Error): string => {
  if (axios.isAxiosError(error) && getAxiosErrorMessage(error)) {
    debugLog(
      `${getAxiosStatusCode(error)} - ${getAxiosErrorMessage(error)}`,
      'Axios error'
    );

    return getAxiosErrorMessage(error).replace('\n', '<br />');
  }

  debugLog(error.message, 'Error');
  return error.toString();
};
