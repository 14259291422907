import { ref } from 'vue';
import { AxiosResponse } from 'axios';
import { EUpdateStates } from '@/aux/enums';
import errorHandler from '@/aux/errorHandler';

export type TuseAPIResult = {
  data: string;
  state: EUpdateStates;
  errors: string[];
};

export function useAPI() {
  // -- Ref variables -- //
  const apiResult = ref<TuseAPIResult>({
    state: EUpdateStates.NO,
    errors: [],
    data: '',
  });

  function callAPI<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends (...args: any[]) => Promise<AxiosResponse>
  >(submitFunction: T, ...args: Parameters<T>) {
    apiResult.value.state = EUpdateStates.PROGRESS;

    submitFunction(...args)
      .then((res) => {
        apiResult.value.data = res.data;
        apiResult.value.state = EUpdateStates.SUCCESS;
      })
      .catch((err) => {
        apiResult.value.errors.push(errorHandler(err as Error));
        apiResult.value.state = EUpdateStates.ERROR;
      });
  }

  return { apiResult, callAPI };
}
