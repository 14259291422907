export const validateNewPassword = (newPassword: string) => {
  const MIN_PASSWORD_LENGTH = 8;
  const CAPITAL_CASE = /[A-Z]/;
  const LOWER_CASE = /[a-z]/;
  const NUMBERS = /[0-9]/;
  const SPECIAL_CHARACTERS = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

  if (newPassword.length <= MIN_PASSWORD_LENGTH) return false;
  if (!CAPITAL_CASE.test(newPassword)) return false;
  if (!LOWER_CASE.test(newPassword)) return false;
  if (!SPECIAL_CHARACTERS.test(newPassword)) return false;
  if (!NUMBERS.test(newPassword)) return false;

  return true;
};

export const validateEmail = (email: string) => {
  // Based on: https://stackabuse.com/validate-email-addresses-with-regular-expressions-in-javascript/
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // eslint-disable-next-line no-control-regex
    //"([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  );

  return emailRegex.test(email);
};
