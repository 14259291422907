// -- External modules -- //
import moment from 'moment';
import axios, { AxiosResponse } from 'axios';
import FormData from 'form-data';

// -- Import vue router -- //
import router from '@/router';

// -- Import interfaces, enums, etc. -- //
import {
  IAircraft,
  EAircraftStatus,
  IAircraftCreate,
} from '@interfaces/Aircraft';
import { IDefect, EDefectStatus } from '@interfaces/Defect';
import { IAircraftType, IAircraftTypeCreate } from '@interfaces/AircraftType';
//import { IKoel } from '@interfaces/Koel';

// -- Import config data -- //
import { SERVER_DATA } from '@/aux/app_configuration';

// -- Import authentication methods -- //
import { refreshAccessToken, logoutTroubleReport } from '@/aux/authentication';
import debugLog from '@/aux/debugLog';

// -- Import auxiliary functions -- //
import { getAxiosStatusCode } from '@/aux/aux_functions';
import { IUser, IUserCreate } from '@interfaces/User';
import { IKoelCreate } from '@interfaces/Koel';

// -- Constants -- //
const SERVER = `${SERVER_DATA.API}`;
export const DEFECT_IMAGES = `${SERVER_DATA.DEFECT_IMAGES}`;

export const HTTP_STATUS = SERVER_DATA.RESPONSE;

// -- Axios instances -- //
const axiosTroubleReport = axios.create({
  baseURL: SERVER,
});

// -- Axios interceptors -- //
axiosTroubleReport.interceptors.request.use(
  (config) => {
    const accessToken =
      window.localStorage.getItem('troubleReportAccessToken') || '';

    config.headers = {
      ...config.headers,
      authorization: `Bearer ${accessToken}`,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

axiosTroubleReport.interceptors.response.use(
  (response) => response, // Code gets executed if HTTP status code in 2xx range is received
  async (error) => {
    // Code gets executed if HTTP status code outside 2xx range is received
    const requestStatusCode = getAxiosStatusCode(error); //error.response.status;
    const requestConfig = error?.config;

    // Check if server returned Unauthorized status code and we did not yet
    // attempt to get a new access code
    if (
      requestStatusCode === HTTP_STATUS.UNAUTHORIZED &&
      !requestConfig?.sent
    ) {
      debugLog(
        'Unauthorized: trying to refresh access token..',
        'Axios interceptor'
      );

      // Indicate that we attempted to get a new access code
      requestConfig.sent = true;

      if (await refreshAccessToken()) {
        debugLog('New access token obtained', 'Axios interceptor');
        const accessToken =
          window.localStorage.getItem('troubleReportAccessToken') || '';

        requestConfig.headers = {
          ...requestConfig.headers,
          authorization: `Bearer ${accessToken}`,
        };

        return axiosTroubleReport(requestConfig);
      }

      debugLog('Unable to obtain new access token', 'Axios interceptor');

      // Unable to obtain new access code
      await logoutTroubleReport();
      router.push({ name: 'login', params: { forceLogout: 'true' } });

      // Prevent catch-code from being executed
      return new Promise(() => {});
    }

    // All other errors: proceed with component error handling
    return Promise.reject(error);
  }
);

export const getAllTroubleReports = (): Promise<AxiosResponse> => {
  return axiosTroubleReport.get('/aircraft');
};

export const get_trouble_report = (_id: string): Promise<AxiosResponse> => {
  const _res = axiosTroubleReport.get(`/aircraft/${_id}`);

  return _res;
};

export const get_all_registrations = (): Promise<AxiosResponse> => {
  const _res = axiosTroubleReport.get(`/aircraft/registrations`);

  return _res;
};

export const create_trouble_report_entry = (
  fd: FormData
): Promise<AxiosResponse> => {
  const _res = axiosTroubleReport.post(`/create-entry`, fd, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  return _res;
};

// Return all defects filtered by status
export const get_defect_by_status = (
  _defects: IDefect[] | undefined,
  _status: EDefectStatus | EDefectStatus[]
) => {
  if (!_defects) return [] as IDefect[];

  if (!Array.isArray(_status))
    return _defects.filter((defect) => defect.status === _status);

  return _defects.filter((defect) =>
    _status.some((_s) => _s === defect.status)
  );
};

// Compute whether the aircraft has any open or deferred defects
export const open_or_deferred_defects = (_aircraft: IAircraft) => {
  if (
    _aircraft?.defects &&
    get_defect_by_status(_aircraft?.defects, EDefectStatus.OPEN).length > 0
  )
    return true;
  if (
    _aircraft?.defects &&
    get_defect_by_status(_aircraft?.defects, EDefectStatus.DEFERRED).length > 0
  )
    return true;

  return false;
};

// Development functions
/*
export const get_koel = (
  //aircraft_type: string,
  koelSystemId: string
  //variant?: number
): Promise<AxiosResponse<IKoel>> => {
  const _res = axiosTroubleReport.post(`/koel`, {
    koelSystemId,
    //aircraft_type,
    //system,
    //variant,
  });

  return _res;
};
*/

export const get_koel_references = (
  type: string,
  getMainSystemsOnly = false
): Promise<AxiosResponse> => {
  const _res = axiosTroubleReport.post(`/koel/references/${type}`, {
    getMainSystemsOnly,
  });

  return _res;
};

export const update_aircraft = (
  id: string,
  updates: object
): Promise<AxiosResponse> => {
  const _res = axiosTroubleReport.post(`/aircraft/${id}/update`, {
    updates,
  });

  return _res;
};

export const addAircraft = (
  newAircraft: IAircraftCreate
): Promise<AxiosResponse> => {
  const result = axiosTroubleReport.post(`/aircraft/add`, {
    newAircraft,
  });

  return result;
};

export const updateDefect = (
  defectID: string,
  updates: object
): Promise<AxiosResponse> => {
  const _res = axiosTroubleReport.post(`/defect/${defectID}`, {
    //defectID,
    updates,
  });

  return _res;
};

export const getS3ImageURL = async (key: string): Promise<string> => {
  const url = (await axiosTroubleReport.get(`/images/${key}`)).data.url;

  return url;
};

export const getUserData = (): Promise<AxiosResponse<IUser>> => {
  return axiosTroubleReport.get(`/user`);
};

export const updateUserPassword = (
  passwordEntries: object
): Promise<AxiosResponse> => {
  return axiosTroubleReport.post(`/user/updatePassword`, passwordEntries);
};

export const getDefectEmailUsers = (
  aircraftId: string
): Promise<AxiosResponse<IUser>> => {
  return axiosTroubleReport.get(`/aircraft/${aircraftId}/defectEmail`);
};

export const setDefectEmailUsers = (
  aircraftId: string,
  userId: string,
  deleteUser = false
): Promise<AxiosResponse<IUser>> => {
  return axiosTroubleReport.post(`/aircraft/${aircraftId}/defectEmail`, {
    userId,
    deleteUser,
  });
};

export const searchUsers = (
  searchTerm: string,
  populateAircraftFilter = false
): Promise<AxiosResponse<Array<IUser>>> => {
  return axiosTroubleReport.post('/user/search', {
    searchTerm,
    populateAircraftFilter,
  });
};

export const createUser = (
  user: IUserCreate,
  adviseByEmail = false
): Promise<AxiosResponse> => {
  return axiosTroubleReport.post('/user/add', {
    userId: user.user_id,
    firstName: user.first_name,
    familyName: user.family_name,
    userEmail: user.email,
    role: user.role,
    adviseByEmail: adviseByEmail,
    aircraftFilter: user.aircraftFilter?.map((aircraft) => aircraft._id),
  });
};

export const updateUser = (
  userDbId: string,
  userChanges: Partial<IUser>
): Promise<AxiosResponse> => {
  return axiosTroubleReport.post('/user/modify', {
    userDbId: userDbId,
    userChanges: userChanges,
  });
};

export const searchAircraft = (
  searchTerm: string
): Promise<AxiosResponse<Array<IAircraft>>> => {
  return axiosTroubleReport.post('/aircraft/search', {
    searchTerm,
  });
};

export const searchAircraftType = (
  searchTerm: string
): Promise<
  AxiosResponse<
    Array<
      {
        numberOfAircraftInDatabase: number;
      } & IAircraftType
    >
  >
> => {
  return axiosTroubleReport.post('/aircraft-type/search', {
    searchTerm,
  });
};

export const updateAircraftType = (
  id: string,
  updates: Partial<IAircraftType>
): Promise<AxiosResponse> => {
  const result = axiosTroubleReport.post(`/aircraft-type/${id}/update`, {
    updates,
  });

  return result;
};

export const deleteAircraftType = (id: string): Promise<AxiosResponse> => {
  const result = axiosTroubleReport.post(`/aircraft-type/${id}/delete`);

  return result;
};

export const addAircraftType = (
  newAircraftType: IAircraftTypeCreate
): Promise<AxiosResponse> => {
  const result = axiosTroubleReport.post(`/aircraft-type/add`, {
    newAircraftType,
  });

  return result;
};

export const findDefectsByKoel = (
  koelSystemId: string
): Promise<AxiosResponse<Array<IDefect>>> => {
  return axiosTroubleReport.post(`/koel/showDefects`, {
    koelSystemId,
  });
};

export const updateKoel = (
  koelObject: IKoelCreate,
  _id?: string
): Promise<AxiosResponse> => {
  const result = axiosTroubleReport.post(`/koel/update`, {
    koelObject,
    _id,
  });

  return result;
};

export const deleteKoel = (_id: string): Promise<AxiosResponse> => {
  const result = axiosTroubleReport.post(`/koel/delete`, {
    _id,
  });

  return result;
};

// Determine background-colour depending on aircraft state
export const aircraftStatusColor = (aircraft: IAircraft): string => {
  if (aircraft.status === EAircraftStatus.INACTIVE) return 'bg-secondary';

  if (aircraft.status === EAircraftStatus.GROUNDED) return 'bg-danger';

  if (
    aircraft?.defects &&
    get_defect_by_status(aircraft?.defects, EDefectStatus.OPEN).length > 0
  )
    return 'bg-warning';

  if (
    aircraft?.defects &&
    aircraft?.defects.some((defect) => {
      return deferralExpired(defect);
    })
  )
    return 'bg-warning';

  if (aircraft.status === EAircraftStatus.OPERATIONAL) return 'bg-success';

  return '';
};

export const deferralExpired = (defect: IDefect): boolean => {
  if (defect.status !== EDefectStatus.DEFERRED) return false;

  if (
    defect?.deferred_date &&
    moment(defect?.deferred_date).isBefore(moment().subtract(1, 'days'))
  )
    return true;

  return false;
};

export const deferralAlmostExpired = (defect: IDefect): boolean => {
  if (defect.status !== EDefectStatus.DEFERRED) return false;

  if (
    defect?.deferred_date &&
    moment(defect?.deferred_date).isBefore(moment().add(7, 'days'))
  )
    return true;

  return false;
};

export const deferralExpiredColor = (defect: IDefect): string => {
  if (deferralExpired(defect)) return 'deferral-expired';
  if (deferralAlmostExpired(defect)) return 'deferral-almostexpired';

  return '';
};
