import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
//import CreateReportView from '@/views/CreateReportView.vue';
import EmptyView from '@/views/EmptyView.vue';
import NavHeader from '@/components/navigation/NavHeader.vue';
import FooterMessage from '@/components/modals/FooterMessage.vue';
const CreateReportView = () => import('@/views/CreateReportView.vue');
const AircraftView = () => import('@/views/AircraftView.vue');
const FleetOverview = () => import('@/views/FleetOverviewView.vue');
const UserProfileView = () => import('@/views/UserProfileView.vue');
import LoginView from '@/views/LoginView.vue';
const ResetPasswordView = () => import('@/views/ResetPasswordView.vue');
const UserAdminView = () => import('@/views/UserAdminView.vue');
const AircraftAdminView = () => import('@/views/AircraftAdminView.vue');
const AircraftTypeAdminView = () => import('@/views/AircraftTypeAdminView.vue');
import { accessTokenLocalStorageSet, userRole } from '@/aux/authentication';
import { getFrontendConfig, APP_SETTINGS } from '@/aux/app_configuration';
import { EUserRole } from '@interfaces/User';

import mem from 'mem';

const SEC_TO_MILLISEC = 1000;
const MIN_TO_SEC = 60;
const HR_TO_MIN = 60;

const memoizedGetFrontendConfig = mem(getFrontendConfig, {
  maxAge:
    parseInt(APP_SETTINGS('APP_CONFIGURATION_CACHE_TIME') as string) ||
    1.0 * HR_TO_MIN * MIN_TO_SEC * SEC_TO_MILLISEC,
});

// Function that is used on routes requiring a user to be logged in:
//  - It checks if an access token is set
//  - If not the user is re-directed to the login page
//  - This by-passes server-side checking:
//       => Avoids unnecessary request to server
//       => Quicker
//  - Of course server side checking still needs to be done!
const protectedRoutes = async (
  _to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  minimumRole: EUserRole = EUserRole.VIEWER
) => {
  await memoizedGetFrontendConfig(true);

  if (!accessTokenLocalStorageSet()) {
    const originName = _to.name?.toString();
    const originPath = _to.path?.toString();

    return { name: 'login', params: { originName, originPath } };
  }

  if (userRole() < minimumRole) {
    return { name: from.name ?? 'home' };
  }
};

const unProtectedRoutes = async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized
) => {
  await memoizedGetFrontendConfig(true);

  if (accessTokenLocalStorageSet() && !(_to.params?.forceLogout === 'true')) {
    return { name: 'fleet-overview' };
  }
};

const setPageTitle = (page: string) => {
  return `${APP_SETTINGS('APP_TITLE')} - ${page}`;
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/fleet-overview',
    meta: {
      title: setPageTitle('Home'),
      short_title: 'Home',
    },
    beforeEnter: async (to, from) => unProtectedRoutes(to, from),
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: LoginView,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('Login'),
      short_title: 'Login',
    },
    beforeEnter: async (to, from) => unProtectedRoutes(to, from),
  },
  {
    path: '/fleet-overview',
    name: 'fleet-overview',
    components: {
      default: FleetOverview,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('Fleet Overview'),
      short_title: 'Fleet Overview',
    },
    beforeEnter: async (to, from) => protectedRoutes(to, from),
  },
  {
    path: '/create-report',
    name: 'create-report',
    components: {
      default: CreateReportView,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('Create Report'),
      short_title: 'Create Report',
    },
    beforeEnter: async (to, from) => protectedRoutes(to, from, EUserRole.USER),
  },
  {
    path: '/fleet-overview/:_id',
    name: 'aircraft-details',
    components: {
      default: AircraftView,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('Aircraft Trouble Report'),
      short_title: 'Aircraft Trouble Report',
    },
    beforeEnter: async (to, from) => protectedRoutes(to, from),
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    components: {
      default: UserProfileView,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('User Profile'),
      short_title: 'User Profile',
    },
    beforeEnter: async (to, from) => protectedRoutes(to, from),
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    components: {
      default: ResetPasswordView,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('Reset password'),
      short_title: 'Reset password',
    },
    beforeEnter: async (to, from) => unProtectedRoutes(to, from),
  },
  {
    path: '/admin/user',
    name: 'admin-user',
    components: {
      default: UserAdminView,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('User admin'),
      short_title: 'User admin',
    },
    beforeEnter: async (to, from) => protectedRoutes(to, from),
  },
  {
    path: '/admin/aircraft',
    name: 'admin-aircraft',
    components: {
      default: AircraftAdminView,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('Aircraft admin'),
      short_title: 'Aircraft admin',
    },
    beforeEnter: async (to, from) => protectedRoutes(to, from),
  },
  {
    path: '/admin/aircraft-type',
    name: 'admin-aircraft-type',
    components: {
      default: AircraftTypeAdminView,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('Aircraft type admin'),
      short_title: 'Aircraft type admin',
    },
    beforeEnter: async (to, from) => protectedRoutes(to, from),
  },
  {
    path: '/test',
    name: 'test',
    components: {
      default: EmptyView,
      NavHeader,
      FooterMessage,
    },
    meta: {
      title: setPageTitle('Test view'),
      short_title: 'Test view',
    },
    beforeEnter: async (to, from) => protectedRoutes(to, from, EUserRole.ADMIN),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/fleet-overview',
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(
  (to: RouteLocationNormalized, _from: RouteLocationNormalized) => {
    const _title = (to?.meta?.title as string) || 'default title';
    document.title = _title;
  }
);

export default router;
