import { IDefect } from './Defect';
import { IUser } from './User';
import { IAircraftType } from './AircraftType';

/*
export enum EAircraftType {
  FNPTII_DA42 = 'FNPTII DA42',
  FNPTII_A320 = 'FNPTII A320',
  DA40D = 'DA40D',
  DA42 = 'DA42',
  DR401 = 'DR401',
  UNDEFINED = 'Undefined',
}
*/

export enum EAircraftStatus {
  OPERATIONAL = 'Operational',
  GROUNDED = 'Grounded',
  INACTIVE = 'Inactive',
  HIDDEN = 'Hidden',
}

export enum EApprovedOperations {
  VFR = 'Day VFR',
  NVFR = 'Night VFR',
  IFR = 'IFR',
  ICING = 'Known or forecast icing',
  FSTD = 'FSTD',
}

/*
export enum EAircraftOrFSTD {
  AIRCRAFT = 'Aircraft',
  FSTD = 'FSTD',
  UNDEFINED = 'Undefined',
}
*/

export interface IAircraft {
  _id: string;
  /** Aircraft registration (e.g., OO-SKX) */
  registration: string;
  /** Aircraft manufacturer (e.g., Diamond) */
  // manufacturer: string;
  /** Aircraft type (e.g., DA40D) */
  //type: EAircraftType;
  type: IAircraftType;
  /** Aircraft or FSTD */
  // aircraft_or_fstd: EAircraftOrFSTD;
  /** Aircraft status: OPERATIONAL / GROUNDED  / INACTIVE*/
  status: EAircraftStatus;
  /** Approved aircraft operations: VFR / NVFR / IFR / FSTD */
  approved_operations: EApprovedOperations[];
  /** Array of aircraft defects */
  defects?: Array<IDefect>;
  /** Remarks */
  remarks?: string;
  /** Array of e-mail addresses to be advised on new defect entry */
  emailUsers?: Array<IUser>;
  /** Array of phone numbers to be advised on new defect entry */
  phoneUsers?: Array<IUser>;
  /** Optional flag to indicate data was loaded from external source */
  externalSource?: boolean;
}

// Reduced version of IUser with the data that needs to be
// supplied when a new user is created
// (_id, salt & password are automatically generated on the backend)
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAircraftCreate extends Omit<IAircraft, '_id' | 'type'> {
  type: IAircraftType | string;
}

// Returns an array contains the properties (as a string) of an interface
type ExtractStringPropertyNames<T> = {
  [K in keyof T]: T[K] extends string | undefined ? K : never;
}[keyof T];

// Array containing the properties (as a string) of IAircraftCreate
export type IAircraftStringProperties = NonNullable<
  ExtractStringPropertyNames<IAircraftCreate>
>;

// A key of IAircraft
export type IAircraftKeys = keyof IAircraft;

// The types contained in IAircraft
export type IAircraftTypes = IAircraft[IAircraftKeys];

export const IAircraftKeysMap: Partial<
  Record<
    keyof IAircraft,
    {
      label: string;
      required: boolean;
      validationFunction: (value: IAircraftTypes) => boolean;
      invalidInputText?: string;
    }
  >
> = {
  registration: {
    label: 'Registration',
    required: true,
    validationFunction: (value: IAircraftTypes) => {
      if (typeof value === 'string' && value.length > 3) return true;

      return false;
    },
    invalidInputText: "Please enter the aircraft's registration",
  },
  type: {
    label: 'Type',
    required: true,
    validationFunction: (value: IAircraftTypes) => {
      if (typeof value === 'string' && value.length > 0) return true;

      if (typeof value === 'object' && '_id' in value) return true;

      return false;
    },
    invalidInputText: "Please enter the aircraft's type",
  },
  status: {
    label: 'Status',
    required: true,
    validationFunction: (value: IAircraftTypes) => {
      if (typeof value === 'string' && value.length > 2) return true;

      return false;
    },
    invalidInputText: "Please select the aircraft's status",
  },
  approved_operations: {
    label: 'Approved operations',
    required: false,
    validationFunction: (_value) => true,
  },
  remarks: {
    label: 'Remarks',
    required: false,
    validationFunction: (_value) => true,
  },
};
