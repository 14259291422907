/*
export enum EUserRoles {
  USER,
  OPS,
  ADMIN,
}
*/

export enum EUpdateStates {
  NO,
  PROGRESS,
  SUCCESS,
  ERROR,
}

export enum EUserViewModes {
  NONE,
  VIEW,
  CHANGE,
  ADD,
}

export enum EAircraftViewModes {
  NONE,
  CHANGE,
  ADD,
}

export enum EAircraftTypeViewModes {
  NONE,
  CHANGE,
  ADD,
}
