import { ComputedRef } from 'vue';
import _ from 'lodash';

export interface IformOptions {
  inputType?: string;
  errorText?: string;
  placeholderText?: string;
  requiredInput?: boolean | ComputedRef<boolean>;
  inputElementIsDisabled?: boolean;
  label?: string;
}

export interface IformProperty {
  value: string;
  inputIsValid?: { (): boolean };
  options?: IformOptions;
}

export interface IformProperties {
  [key: string]: IformProperty;
}

export const formEntriesComplete = (formData: IformProperties): boolean => {
  const entriesComplete = _.reduce(
    formData,
    (formIsValid, formProperty, _key) => {
      return formIsValid && (formProperty?.inputIsValid?.() ?? true);
    },
    true
  );

  return entriesComplete;
};

export const clearFormEntries = (formData: IformProperties): void => {
  _.forEach(formData, (item) => {
    item.value = '';
  });
};
