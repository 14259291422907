import { EUserRole, IUser } from './User';
import { IKoel } from './Koel';
import { IAircraft } from './Aircraft';

enum EDefectStatus {
  OPEN = 'Open',
  DEFERRED = 'Deferred',
  CLOSED = 'Closed',
  HIDDEN = 'Hidden',
}

interface IKoelReference {
  details: IKoel | string;
  variant: number;
}

const arrayDateKeys = ['entry_date'] as const;
const arrayOptionalDateKeys = ['deferred_date', 'closed_date'] as const;

export const DEFECT_DATE_KEYS = [...arrayDateKeys, ...arrayOptionalDateKeys];

type TDateKeys = Record<typeof arrayDateKeys[number], Date>;
type TOptionalDateKeys = Record<typeof arrayOptionalDateKeys[number], Date>;

interface IDefect extends Partial<TOptionalDateKeys>, TDateKeys {
  _id: string;
  aircraft: IAircraft;

  title: string;
  description: string;
  entered_by: IUser | string;
  //entry_date: Date;
  status: EDefectStatus;
  koel_reference?: IKoelReference;

  //deferred_date?: Date;
  deferred_by?: IUser | string;
  remarks?: string;

  //closed_date?: Date;
  closed_by?: IUser | string;
  corrective_action?: string;

  images?: string[];

  defect_reference: string;
}

const DEFECT_MODIFICATION_PERMISSIONS = new Map([
  [EUserRole.UNAUTHORIZED, []],
  [EUserRole.VIEWER, []],
  [EUserRole.USER, []],
  [
    EUserRole.OPS,
    [
      'status',
      'koel_reference',
      'deferred_date',
      'remarks',
      'corrective_action',
    ],
  ],
  [
    EUserRole.ADMIN,
    [
      'title',
      'description',
      //'entered_by',
      'entry_date',
      'status',
      'koel_reference',
      'deferred_date',
      //'deferred_by',
      'remarks',
      'closed_date',
      //'closed_by',
      'corrective_action',
    ],
  ],
]);

const DEFECT_STATUS_PROPERTIES = new Map([
  [EDefectStatus.CLOSED, ['closed_date', 'closed_by', 'corrective_action']],
  [EDefectStatus.DEFERRED, ['deferred_date', 'deferred_by', 'remarks']],
]);

const userCanModify = <IDefectKey extends keyof IDefect>(
  userRole: EUserRole,
  property: IDefectKey
): boolean => {
  return (DEFECT_MODIFICATION_PERMISSIONS.get(userRole) || []).includes(
    property
  );
};

export {
  IDefect,
  EDefectStatus,
  IKoelReference,
  userCanModify,
  DEFECT_STATUS_PROPERTIES,
};

export function isFullKoel(koel: IKoel | string): koel is IKoel {
  return (koel as IKoel)._id !== undefined;
}

export function isFullUser(user: IUser | string): user is IUser {
  return (user as IUser)._id !== undefined;
}
