import axios, { AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';

import { SERVER_DATA } from '@/aux/app_configuration';
import { EUserRole, IUserTokenPayload } from '@interfaces/User';
import errorHandler from '@/aux/errorHandler';

const LOCAL_ACCESS_TOKEN = 'troubleReportAccessToken';

// -- Axios instance -- //
const axiosLogin = axios.create({
  baseURL: `${SERVER_DATA.API}`,
});

const setAccesTokenLocalStorage = (accessToken: string) => {
  window.localStorage.setItem(LOCAL_ACCESS_TOKEN, accessToken);
};

export const logoutTroubleReport = async () => {
  /** Perform logout on server
   *   => refresh token cookie will be cleared
   *   => refresh token will be removed from database
   */
  await axiosLogin.get('/logout', {
    withCredentials: true,
  });

  // Locally remove the access token
  window.localStorage.removeItem(LOCAL_ACCESS_TOKEN);
};

export const accessTokenLocalStorageSet = () => {
  return window.localStorage.getItem(LOCAL_ACCESS_TOKEN) ? true : false;
};

/**
 * Perform login to API
 * @param formData Login credentials
 * @returns Promise: null if login is successful; <string>server error message if login is unsuccessful
 */
export const loginTroubleReport = async (formData: {
  userEmail: string;
  userPassword: string;
}) => {
  try {
    const loginRequest = await axiosLogin.post('/login', formData, {
      withCredentials: true,
    });

    setAccesTokenLocalStorage(loginRequest.data);

    return Promise.resolve(null);
  } catch (error) {
    return Promise.resolve(errorHandler(<Error>error));
  }
};

export const refreshAccessToken = async () => {
  try {
    const refreshTokenRequest = await axiosLogin.get('/login/refresh', {
      withCredentials: true,
    });

    setAccesTokenLocalStorage(refreshTokenRequest.data);

    return Promise.resolve(true);
  } catch (error) {
    errorHandler(<Error>error);
  }

  return Promise.resolve(false);
};

export const getUserDetailsFromAccessToken = (): IUserTokenPayload => {
  const accesTokenAsString = window.localStorage.getItem(LOCAL_ACCESS_TOKEN);
  const accessToken = accesTokenAsString
    ? (jwt_decode(accesTokenAsString) as IUserTokenPayload)
    : {
        dbId: 'NIL',
        id: 'ZZZ',
        name: 'John Doe',
        role: EUserRole.UNAUTHORIZED,
      };

  return accessToken;
};

export const getPasswordResetToken = (
  email: string
): Promise<AxiosResponse> => {
  return axiosLogin.get(`/user/resetPassword/${email}`);
};

export const resetUserPassword = (
  resetToken: string,
  passwordEntries: object
): Promise<AxiosResponse> => {
  const headers = {
    authorization: `Bearer ${resetToken}`,
  };

  return axiosLogin.post(`/user/resetPassword`, passwordEntries, {
    headers: headers,
  });
};

export const userRole = (): EUserRole => {
  return getUserDetailsFromAccessToken().role;
};

export const userRoleDescription = (): string => {
  const userRoleAsInt = userRole();
  return EUserRole[userRoleAsInt];
};

export const userName = (): string => {
  return getUserDetailsFromAccessToken().name;
};

export const userID = (): string => {
  return getUserDetailsFromAccessToken().id;
};

export const userDbId = (): string => {
  return getUserDetailsFromAccessToken().dbId;
};
